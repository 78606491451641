// --------- import external dependencies ----------
import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; 

// import Contact from "../screens/Contact";

// --------- import screen component with lazy load ----------
const Home = lazy(() => import("../screens/Home"));
const About = lazy(() => import("../screens/About"));
const Team = lazy(() => import("../screens/Team"));
const Terms = lazy(() => import("../screens/Terms"));
const Policy = lazy(() => import("../screens/Policy"));
 const Disclaimer = lazy(() => import("../screens/Disclaimer"));





// const Contact = lazy(() => import("../screens/Contact"));






function AppRoute() {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <Routes>
             <Route exact path="/" element={<Home />} /> 
           <Route exact path="/about" element={<About/>} />
           <Route exact path="/team" element={<Team/>} />
           <Route exact path="/terms" element={<Terms/>} />
           <Route exact path="/policy" element={<Policy/>} />
           <Route exact path="/disclaimer" element={<Disclaimer/>} />


         



              {/* <Route exact path="/contact" element={<Contact/>} />  */}
    
            
          </Routes>
        </Router>
      </Suspense>
  
  
    );
  }
  
  export default AppRoute;